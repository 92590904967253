import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Avatar, Badge, Button, ListItemIcon, Menu, MenuItem} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import * as React from "react";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../store";
import {useNavigate} from "react-router-dom";
import {resetLoginState} from "../slices/loginSlice";
import {setAssetType, setSelectedLHSMenuId} from "../slices/global";
import {useState} from "react";
import {RootState} from "../slices";
import {Chat, Logout, PersonAdd, Settings} from "@mui/icons-material";
import Divider from "@mui/material/Divider";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({open}) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

const pages = [
    {
        title: 'Fleet',
        id: 'fleet',
        assetType: 'fleet',
        url: '/fleet/overview',
    },
    {
        title: 'Wind',
        id: 'wind',
        assetType: 'wind',
        byDefaultLHSSelectedId: 'wind_overview',
        url: '/wind/overview',
    },
    {
        title: 'Solar',
        id: 'solar',
        assetType: 'solar',
        byDefaultLHSSelectedId: 'solar_overview',
        url: '/solar/overview',
    }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

interface TopNavProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TopNavigation: React.FC<TopNavProps> = ({isSidebarOpen, setIsSidebarOpen}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {assetType} = useSelector((state: RootState) => state.global);
    const handleDrawerOpen = () => {
        setIsSidebarOpen(true);
    };

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        dispatch(resetLoginState());
        localStorage.removeItem('token');
        navigate('/login');
    }

    return (
        <AppBar position="fixed" open={isSidebarOpen}>
            <Toolbar>
                {
                    (assetType !== 'fleet' && assetType !== 'settings') &&
                    <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={[{marginRight: 5,},
                        isSidebarOpen && {display: 'none'},
                    ]}>
                        <MenuIcon/>
                    </IconButton>
                }
                <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    onClick={() => {
                        navigate('/fleet/overview');
                        dispatch(setSelectedLHSMenuId(null));
                        dispatch(setAssetType('fleet'))
                    }}
                    sx={{
                        mr: 2,
                        cursor: 'pointer',
                        display: {xs: 'none', md: 'flex'},
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}>
                    LOGO
                </Typography>
                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                    {
                        pages.map((page) => (
                            <Button
                                variant="outlined"
                                key={page.title}
                                onClick={() => {
                                    navigate(page.url);
                                    dispatch(setSelectedLHSMenuId(page.byDefaultLHSSelectedId));
                                    dispatch(setAssetType(page.assetType))
                                }}
                                sx={{
                                    borderRadius: 0,
                                    my: 2,
                                    color: 'white',
                                    display: 'block',
                                    borderBottom: page.id === assetType ? '2px solid #fff' : '0px solid #fff'}}
                            >
                                {page.title}
                            </Button>
                        ))
                    }

                </Box>
                <Box sx={{flexGrow: 0}}>
                    <IconButton onClick={() => {
                        navigate('/aichat');
                    }} size="large" color="inherit">
                        <Chat />
                    </IconButton>
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={4} color="error">
                            <MailIcon/>
                        </Badge>
                    </IconButton>
                    <IconButton
                        onClick={() => navigate('/notification-centre')}
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit">
                        <Badge badgeContent={17} color="error">
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="account of current user"
                        onClick={handleOpenUserMenu}>
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                        hidden={true}
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{vertical: 'top', horizontal: 'right',}}
                        keepMounted
                        transformOrigin={{vertical: 'top', horizontal: 'right',}}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}>
                        <MenuItem key={settings[0]} onClick={handleCloseUserMenu}>
                            <Typography sx={{textAlign: 'center'}}>{settings[0]}</Typography>
                        </MenuItem>
                        <MenuItem key={settings[3]} onClick={handleLogout}>
                            <Typography sx={{textAlign: 'center'}}>{settings[3]}</Typography>
                        </MenuItem>
                    </Menu>

                    <Menu
                        anchorEl={anchorElUser}
                        id="account-menu"
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        onClick={handleCloseUserMenu}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Avatar /> Profile
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            navigate('/admin/settings');
                            dispatch(setAssetType('settings'))
                        }}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Configurations
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default TopNavigation;

