import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Input} from "@mui/material";
import {Delete, Download, UpdateSharp} from "@mui/icons-material";
import {HighchartsReact} from "highcharts-react-official";
import * as Highcharts from "highcharts";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import {Alert} from "@mui/lab";
import pcCrudChartOptions from "../../../../data/chartOptions/pc_crud_chart_options";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../store";
import Papa from 'papaparse';
import {addNewPC, deletePC, fetchData, resetAddStatus} from "../../../../slices/pcCrudSlice";

interface PowerCurveInput {
    wind_speed: number;
    power_output: number;
}

const PowerCurveConfig: React.FC = () => {
    const [isPCAvailable, setIsPCAvailable] = useState(false);
    const pcChartRef = useRef<HighchartsReact.RefObject>(null);
    let pcChart = pcChartRef.current?.chart;
    const [chartOptions, setChartOptions] = useState(pcCrudChartOptions);
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    const {
        rowData: pcData, status, error, addStatus
    } = useSelector((state: RootState) => state.pcCurdData);

    const dispatch = useDispatch<AppDispatch>()

    const [openAddPCModal, setOpenAddPCModal] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileError, setError] = useState<string | null>(null);
    const [isValid, setIsValid] = useState(false);
    const [powerCurveData, setPowerCurveData] = useState<PowerCurveInput[]>([]);

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{message: string, alertSeverity: any}>({message: "", alertSeverity: ""});

    const handleOpen = () => setOpenAddPCModal(true);

    const handleClose = () => {
        setOpenAddPCModal(false);
        setFile(null);
        setError(null);
        setIsValid(false);
    };

    // Validate CSV headers
    const validateCsv = (file: File) => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: (result: any) => {
                const headers = result.meta.fields;
                if (headers && headers.length === 2 && headers.includes('wind_speed') && headers.includes('power_output')) {
                    setIsValid(true);
                    setError(null);
                    parseCsvData(result.data as PowerCurveInput[]);
                } else {
                    setIsValid(false);
                    setError("CSV file must have exactly two columns: 'wind_speed' and 'power_output'");
                }
            },
            error: () => {
                setIsValid(false);
                setError("An error occurred while parsing the CSV file.");
            }
        });
    };

    // Parse CSV data and convert to PowerCurveInput array
    const parseCsvData = (data: any[]) => {
        const parsedData: PowerCurveInput[] = data.map((row) => ({
            wind_speed: parseFloat(row.wind_speed),
            power_output: parseFloat(row.power_output)
        }));
        setPowerCurveData(parsedData);
    };

    // Handle file input change
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            validateCsv(selectedFile);
        }
    };

    // Handle file upload
    const handleUpload = () => {
        if (isValid && powerCurveData.length > 0) {
            dispatch(addNewPC({apiURL: '/assets/' + selectedAssetId + '/power-curve', data: powerCurveData}));
            handleClose();
        }
    };

    useEffect(() => {
        if (pcData.length > 0) {
            setIsPCAvailable(true)
            setPowerCurveData(pcData)
            clearSeries();
            let temp: any[] = []
            pcData.forEach((dataPoint: any) => {
                temp.push([dataPoint.wind_speed, dataPoint.power_output])
            })
            const newSeries = {
                type: 'spline',
                name: 'OEM Power Curve',
                data: temp,
            };

            setChartOptions((prevOptions: any) => ({
                ...prevOptions,
                series: [...prevOptions.series, newSeries], // Add new series
            }));

            pcChart?.redraw()
            pcChart?.hideLoading()
        } else if (pcData.length == 0) {
            setIsPCAvailable(false)
            clearSeries();
            pcChart?.hideLoading()
        }
    }, [pcData]);

    // Function to clear all series
    const clearSeries = () => {
        setChartOptions((prevOptions: any) => ({
            ...prevOptions,
            series: [], // Clear the series
        }));
    };

    useEffect(() => {
        if (addStatus === 'succeeded') {
            dispatch(resetAddStatus())
            setUserMessage({message: "PC updated successfully", alertSeverity: "success"});
            setOpenSnackBar(true);
            dispatch(fetchData('oem-power-curve?asset_id=' + selectedAssetId));
        }
    }, [addStatus]);

    const handleCloseSnakeBar = () => {
        setOpenSnackBar(false);
    }

    const deletePowerCurve = () => {
        dispatch(deletePC('/assets/' + selectedAssetId + '/power-curve'));
    }

    const downloadPowerCurve = () => {
        try {
            // Convert the data to CSV format
            const csv = Papa.unparse(powerCurveData, {
                header: true,
                columns: ["wind_speed", "power_output"], // specify the column names
            });

            // Create a blob and trigger download
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `power_curve_asset_${selectedAssetId}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            setUserMessage({message: 'CSV Download failed', alertSeverity: 'error'});
            setOpenSnackBar(true);
        }
    }

    return (
        <Card>
            <CardContent>
                <div className={'mb-4 flex justify-end items-center'}>
                    <Button startIcon={<UpdateSharp />} onClick={handleOpen} variant={'contained'}>
                        {isPCAvailable ? 'Update' : 'Add New'}
                    </Button>
                    {
                        isPCAvailable &&
                        <>
                            <Button startIcon={<Download />} sx={{marginLeft: '1rem'}} onClick={downloadPowerCurve} variant={'contained'}>
                                Download
                            </Button>
                            <Button startIcon={<Delete />} sx={{marginLeft: '1rem'}}  color={'error'} onClick={deletePowerCurve} variant={'contained'}>
                                Delete
                            </Button>
                        </>

                    }
                </div>

                <HighchartsReact
                    highcharts={Highcharts}
                    ref={pcChartRef}
                    options={chartOptions}>
                </HighchartsReact>
            </CardContent>

            <Dialog open={openAddPCModal} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Upload CSV File</DialogTitle>
                <DialogContent>
                    <Input
                        type="file"
                        inputProps={{ accept: ".csv" }}
                        onChange={handleFileChange}
                        fullWidth
                    />
                    {fileError && <Typography color="error">{fileError}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpload}
                        color="primary"
                        variant="contained"
                        disabled={!isValid}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={handleCloseSnakeBar}>
                <Alert
                    onClose={handleCloseSnakeBar}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </Card>
    )
}

export default PowerCurveConfig