import {AgGridReact} from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"; // Optional Theme applied to the Data Grid
import Typography from "@mui/material/Typography";
import {Delete, SearchRounded} from "@mui/icons-material";
import {ClientSideRowModelModule, ModuleRegistry, GridApi, ColDef} from "ag-grid-enterprise"; // Column definition interface
import {ColumnsToolPanelModule} from "ag-grid-enterprise";
import {FiltersToolPanelModule} from "ag-grid-enterprise";
import {SetFilterModule} from "ag-grid-enterprise";
import {useDispatch, useSelector} from "react-redux";
import {fetchData, deleteRow, updateRow, addRow, resetUserCrudState} from "../../slices/userCrudSlice";
import {AppDispatch, RootState} from "../../store";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid2";
import LoadingButton from "@mui/lab/LoadingButton";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
]);

interface AGTableProps {}

const UsersTab: React.FC = (props: AGTableProps) => {
    const gridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);
    const dispatch = useDispatch<AppDispatch>()
    const [tableData, setTableData] = useState<AGTableProps[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<any>(null)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [customerId, setCustomerId] = useState<number | null>(null);
    const [userMessage, setUserMessage] = useState<{message: string, alertSeverity: any}>({message: "", alertSeverity: ""});
    const {userBasicData, userBasicDataError, userBasicDataLoading} = useSelector((state: RootState) => state.userData);

    // Select rowData and status from the Redux store
    const {usersDataFetchLoading, usersData, usersDataFetchError, deleteStatus, updateStatus, addStatus} = useSelector((state: RootState) => state.userCrudData);

    const columns: ColDef[] = [
        {field: 'id', headerName: 'User ID', maxWidth: 200, hide: true},
        {field: 'username', headerName: 'User ID', flex: 1, editable: false},
        {field: 'email', headerName: 'Email', flex: 1, editable: false},
        {field: 'full_name', headerName: 'Full Name', flex: 1},
        {field: 'phone_number', headerName: 'Phone number', flex: 1},
    ];

    const [columnDefs, setColumnDefs] = useState(columns)

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    useEffect(() => {
        if (userBasicData) {
            setCustomerId(userBasicData.customer.id)
            dispatch(fetchData('customers/' + userBasicData.customer.id + '/users'));
        }
    }, [userBasicData]);

    useEffect(() => {
        return () => {
            dispatch(resetUserCrudState());
        }
    }, [])

    useEffect(() => {
        if(addStatus === 'succeeded') {
            handleAddClose()
        }

        if(addStatus === 'failed') {
            setUserMessage({message: "User addition failed", alertSeverity: "error"});
            setOpenSnackBar(true);
        }
    }, [addStatus]);

    useEffect(() => {
        if (deleteStatus === 'succeeded') {
            setUserMessage({message: "User Deleted Successfully", alertSeverity: "success"});
            setOpenSnackBar(true);
        }

        if (deleteStatus === 'failed') {
            setUserMessage({message: "User deletion failed", alertSeverity: "error"});
            setOpenSnackBar(true);
        }

        if (updateStatus === 'succeeded') {
            setUserMessage({message: "User Updated Successfully", alertSeverity: "success"});
            setOpenSnackBar(true);
        }
    }, [deleteStatus, updateStatus]);

    useEffect(() => {
        if (usersData.length > 0) {
            setTableData(JSON.parse(JSON.stringify(usersData)));
        }
    }, [usersData]);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        params.api.closeToolPanel();
        addDeleteColumnDynamically();
    };

    /*-- Function to delete a row --*/
    const handleDeleteRow = (params: any) => {
        setSelectedAsset(params.data);
        setOpenDeleteModal(true);
    };

    const addDeleteColumnDynamically = () => {
        const deleteColumn =
                {
                    headerName: 'Actions',
                    cellRenderer: (params: any) => (
                        <button onClick={() => handleDeleteRow(params)}>
                            <Delete />
                        </button>
                    ),
                }

            setColumnDefs((prevColumnDefs: any) => [...prevColumnDefs, deleteColumn]);
    };

    const handleDeleteClose = () => {
        setOpenDeleteModal(false);
    };

    const handleOk = () => {
        dispatch(deleteRow(selectedAsset.id))
        handleDeleteClose();
    };
    /*-- Function to delete a row --*/

    // Handle cell value changes for updates
    const onCellValueChanged = (params: any) => {
        const updatedRow = params.data;
        dispatch(updateRow(updatedRow));
    };

    const handleAddClose = () => {
        setOpenAddModal(false);
    };

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseSnakeBar = () => {
        setOpenSnackBar(false);
    }

    return (
        // wrapping container with theme & size
        <div style={{width: "100%", height: "700px", display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "16px"}}>
                <div className={'title'}>
                    <Typography variant="h6" component="div">
                        Users Information
                    </Typography>
                </div>
                <div className={'flex flex-row'}>
                    <Button variant={'contained'} onClick={handleOpenAddModal}>Add New User</Button>
                    <div className="relative ml-4">
                        <input
                            type="text"
                            className="border border-gray-300 py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Search..."
                            id='filter-text-box'
                            onChange={onFilterTextBoxChanged}
                        />
                        <span className="w-5 h-5 absolute left-3 transform -translate-y-1/2 text-gray-500"
                              style={{top: "45%"}}>
                        <SearchRounded></SearchRounded>
                    </span>
                    </div>
                </div>
            </div>

            <div style={{height: "100%", width: "100%"}} className={"ag-theme-quartz"}>
                <AgGridReact
                    loading={usersDataFetchLoading}
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowData={tableData}
                    pagination={true}
                    onCellValueChanged={onCellValueChanged}
                    rowSelection="single"
                    animateRows={true}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    sideBar={true}
                />
            </div>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 435 } }}
                maxWidth="sm"
                open={openDeleteModal}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent dividers>
                    <h2 className="text-xl font-semibold text-gray-800">
                        Are you sure you want to delete <strong
                        className="text-red-600">{selectedAsset?.username}</strong>?
                    </h2>
                    <p className="text-gray-600">
                        <strong>Note:</strong> Once deleted, this action cannot be undone.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <LoadingButton onClick={handleOk} loading={addStatus === 'loading'} variant={'contained'}>Yes</LoadingButton>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClose={handleCloseSnakeBar}>
                <Alert
                    onClose={handleCloseSnakeBar}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {userMessage.message}
                </Alert>
            </Snackbar>

            <Dialog
                open={openAddModal}
                fullWidth={true}
                onClose={handleAddClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        formJson['username'] = formJson['email'].split('@')[0];
                        formJson['customer_id'] = customerId
                        dispatch(addRow(formJson));
                    },
                }}>
                <DialogTitle>Add New User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add new user please fill up the following details.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid size={12} hidden={true}>
                            <TextField
                                margin="dense"
                                id="name"
                                name="username"
                                label="User ID"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                required
                                margin="dense"
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                name="full_name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                required
                                margin="dense"
                                id="phone_number"
                                name="phone_number"
                                label="Number"
                                type="number"
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddClose}>Cancel</Button>
                    <LoadingButton loading={addStatus === 'loading'} variant={'contained'} type="submit">Save User</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UsersTab;