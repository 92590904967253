import React from 'react';

const StayTuned: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full">
            <div className="p-8 bg-white rounded-lg shadow-lg text-center w-full">
                <h1 className="text-2xl font-semibold text-gray-800">Upcoming!</h1>
                <p className="mt-4 text-gray-600">
                    We're working on something exciting. Please stay tuned for updates!
                </p>
                <div className="mt-6">
                    <span className="animate-pulse text-gray-500 text-sm">Stay tuned...</span>
                </div>
            </div>
        </div>
    );
};

export default StayTuned;