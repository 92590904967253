// slices/weatherSlice.ts
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Define the initial state type
interface DataState {
    loading: boolean;
    alarmCodesData: any; // Replace 'any[]' with the actual type of your data if needed
    error: string | null;
}

const initialState: DataState = {
    loading: false,
    alarmCodesData: {},
    error: null,
};


export const fetchAlarmCodes = createAsyncThunk('data/alarmCodesData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

const alarmCodeSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        // This action resets the state back to the initial state
        resetAlarmCodeState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAlarmCodes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAlarmCodes.fulfilled, (state, action) => {
                state.loading = false;
                state.alarmCodesData = action.payload;
            })
            .addCase(fetchAlarmCodes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to post data';
            });
    },
});


export const { resetAlarmCodeState } = alarmCodeSlice.actions;
export default alarmCodeSlice.reducer