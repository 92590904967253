import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import React, {useCallback, useMemo, useRef} from "react"; // Optional Theme applied to the Data Grid
import Typography from "@mui/material/Typography";
import {SearchRounded} from "@mui/icons-material";
import {ClientSideRowModelModule, ModuleRegistry, GridApi, SparklinesModule} from "ag-grid-enterprise"; // Column definition interface
import { ColumnsToolPanelModule } from "ag-grid-enterprise";
import { FiltersToolPanelModule } from "ag-grid-enterprise";
import { SetFilterModule } from "ag-grid-enterprise";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    SetFilterModule,
    SparklinesModule
]);

interface AGTableProps {
    rowData: any[]
    colDefs: any[]
    defaultColDef: any
    icon?: any
    title: string
    height?: number
    loading?: boolean,
    paginationPageSize?: number
}

const AGDataTable = (props: AGTableProps) => {
    const gridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api;
        // Explicitly close the sidebar when the grid is ready
        params.api.closeToolPanel();
    };

    const tableHeight = props.height ? props.height + 'px' : "600px";

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [10, 20, 50];
    }, []);

    // ...
    return (
        // wrapping container with theme & size
        <div style={{width: "100%", height: tableHeight, display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "16px"}}>
                <div className={'title'}>
                    <Typography variant="h6" component="div">
                        {props.icon} {props.title}
                    </Typography>
                </div>
                <div className="relative">
                    <input
                        type="text"
                        className="border border-gray-300 py-1 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Search..."
                        id='filter-text-box'
                        onChange={onFilterTextBoxChanged}
                    />
                    <span className="w-5 h-5 absolute left-3 transform -translate-y-1/2 text-gray-500"
                          style={{top: "45%"}}>
                                <SearchRounded></SearchRounded>
                            </span>
                </div>
            </div>
            <div style={{height: "100%", width: "100%"}} className={"ag-theme-quartz"}>
                <AgGridReact
                    loading={props.loading}
                    ref={gridRef}
                    onGridReady={onGridReady}
                    rowData={props.rowData}
                    pagination={true}
                    columnDefs={props.colDefs}
                    defaultColDef={props.defaultColDef}
                    sideBar={true}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationPageSize={props.paginationPageSize ? props.paginationPageSize : 10}  // Set page size to 10 rows
                />
            </div>
        </div>
    )
}

export default AGDataTable;