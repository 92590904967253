import {
    Alarm, BarChart,
    Curtains,
    EventAvailable, StackedLineChart, TrendingDown,
} from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export const wind_performance_tabs = [
    {'icon': StackedLineChart, 'label': 'Power Curve', id: 'performancePowerCurve'},
    {'icon': TrendingDown, 'label': 'Downtime Analysis', id: 'performanceLossAnalysis'},
    {'icon': Alarm, 'label': 'Alarms', id: 'performanceAlarms'},
    {'icon': EventAvailable, 'label': 'Availability', id: 'performanceAvailability'},
    {'icon': Curtains, 'label': 'Curtailment', id: 'performanceCurtailment'},
    {'icon': SettingsOutlinedIcon, 'label': 'Configuration', id: 'settings'},
]