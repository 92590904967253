import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Paper, Chip, Card, CardContent, InputAdornment, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid2";
import PageTileAndBreadCrumb from "../components/PageTileAndBreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchData } from "../slices/pcCrudSlice";
import { fetchAlarmCodes } from "../slices/alarmCodeSlice";
import { fetchAichat } from "../slices/aichatSlice";
import aiChatPrompts from "../data/mock/aiChatPromts.json";

// Define a type for basic prompts
const basicPrompts: string[] = aiChatPrompts;

// Define types for the component’s state
const DataPromptPage: React.FC = () => {
    const [showInitialState, setShowInitialState] = useState(true);
    const [userInput, setUserInput] = useState<string>("");
    const [response, setResponse] = useState<string | null>(null);
    const [data, setData] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { aichatData, aichatLoading } = useSelector((state: RootState) => state.aichatData);
    const { userAssetsData } = useSelector((state: RootState) => state.userData);
    const { rowData: pcData } = useSelector((state: RootState) => state.pcCurdData);
    const { alarmCodesData } = useSelector((state: RootState) => state.alarmCodeData);

    // Function to handle request analysis
    const handleAnalyzeRequest = async () => {
        setError(null);
        setData([]);
        setResponse("Analyzing your request...");
        const data = {
            model: "gpt-4-turbo",
            messages: [
                {
                    role: "system",
                    content: `
                            Analyze the following request to determine:
                            1. The intent: either "fetch_power_curve", "fetch_alarm_codes", or "fetch_status_codes".
                            2. The asset name if mentioned (or "none" if no asset name is provided).
                            
                            Return the result in the format: "Intent: [intent], Asset: [asset_name]".
                            
                            Request: "${userInput}"`,
                },
            ],
            temperature: 0.5,
            max_tokens: 50,
        };

        dispatch(fetchAichat({ apiURL: "api/openai", data: data }));
        setShowInitialState(false);
    };

    const getAssetIDFromAssetName = (assetName: string) => {
        const asset: any = userAssetsData.find((asset) => asset.name.includes(assetName));
        return asset.id;
    };

    useEffect(() => {
        if (aichatData && !showInitialState && !aichatLoading) {
            const { intent, asset } = aichatData;

            if (!intent || intent === "none") {
                setResponse(null);
                setError("Sorry, I couldn't understand your request. Please try asking differently.");
                return;
            }

            if (!asset) {
                setResponse(null);
                setError("Please add an Asset for which you want to execute this request");
                return;
            }

            setResponse(`Detected intent: ${intent}, Asset: ${asset}. Fetching data...`);

            if (intent === "fetch_power_curve") {
                setResponse(null);
                dispatch(fetchData("oem-power-curve?asset_id=" + getAssetIDFromAssetName(asset)));
            }

            if (intent === "fetch_status_codes") {
                setResponse(null);
                dispatch(fetchData("oem-power-curve?asset_id=" + getAssetIDFromAssetName(asset)));
            }

            if (intent === "fetch_alarm_codes") {
                setResponse(null);
                dispatch(fetchAlarmCodes("alarm_codes/asset/" + getAssetIDFromAssetName(asset)));
            }
        }
    }, [aichatData, showInitialState, aichatLoading]);

    const handlePromptClick = (prompt: string) => {
        setUserInput(prompt);
    };

    useEffect(() => {
        if (pcData.length > 0) setData(pcData);
    }, [pcData]);

    useEffect(() => {
        if (alarmCodesData.length > 0) setData(alarmCodesData);
    }, [alarmCodesData]);

    return (
        <Box
            sx={{
                position: "relative",
                minHeight: "100vh",
                bgcolor: "linear-gradient(to right, #e3f2fd, #e0f7fa)",
                pt: 2,
                pb: 8,
            }}
        >
            <Grid container spacing={2} justifyContent="center">
                <PageTileAndBreadCrumb title="Data Access Assistant" />

                <Grid size={12}>
                    <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold" }}>
                                Try one of these example prompts:
                            </Typography>
                            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                                {basicPrompts.map((prompt, index) => (
                                    <Chip
                                        key={index}
                                        label={prompt}
                                        onClick={() => handlePromptClick(prompt)}
                                        variant="outlined"
                                        clickable
                                        sx={{ bgcolor: "#e3f2fd", boxShadow: 1 }}
                                    />
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid size={12}>
                    <Card sx={{ borderRadius: 2, boxShadow: 3, p: 2 }}>
                        {response && <Typography sx={{ color: "text.primary", mb: 1 }}>{response}</Typography>}
                        {error && (
                            <Typography color="error" sx={{ mb: 1 }}>
                                {error}
                            </Typography>
                        )}

                        {data.length > 0 && (
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    Data:
                                </Typography>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        p: 2,
                                        mt: 2,
                                        borderRadius: 1,
                                        bgcolor: "#f5f5f5",
                                        maxHeight: "600px",
                                        overflowX: "auto",
                                    }}
                                >
                                    <pre>{JSON.stringify(data, null, 2)}</pre>
                                </Paper>
                            </Box>
                        )}
                    </Card>
                </Grid>
            </Grid>

            {/* Fixed bottom input section */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    bgcolor: "background.paper",
                    boxShadow: "0 -4px 12px rgba(0,0,0,0.1)",
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        width: "90%",
                        maxWidth: 800,
                        display: "flex",
                        bgcolor: "white",
                        borderRadius: 2,
                        boxShadow: 3,
                    }}
                >
                    <TextField
                        autoFocus={true}
                        fullWidth
                        label="What data would you like to access?"
                        placeholder="Type your request"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleAnalyzeRequest();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="primary"
                                        onClick={handleAnalyzeRequest}
                                        disabled={!userInput.trim()}
                                        sx={{ color: "#42a5f5" }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            bgcolor: "#f5f5f5",
                            borderRadius: 1,
                            boxShadow: 1,
                            input: { paddingLeft: "12px" },
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default DataPromptPage;
