import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from "../utils/interceptor";


// Async thunks for CRUD operations
export const fetchData = createAsyncThunk('data/fetchData', async (apiURL: string) => {
    const response = await api.get(apiURL);
    return response.data;
});

export const addRow = createAsyncThunk('data/addRow', async (newRow: any) => {
    const response = await api.post('users', newRow);
    return response.data;
});

export const updateRow = createAsyncThunk('data/updateRow', async (updatedRow: any) => {
    await api.put(`user/${updatedRow.id}`, updatedRow);
    return updatedRow;
});

export const deleteRow = createAsyncThunk('data/deleteRow', async (rowId: number) => {
    await api.delete(`user/${rowId}`);
    return rowId;
});

// Define the initial state type
interface DataState {
    usersData: any[],
    usersDataFetchLoading: boolean
    usersDataFetchError: string | undefined,
    addStatus: string,
    deleteStatus: string,
    updateStatus: string,
    assetAddError: string | undefined,
}

const initialState: DataState = {
    usersData: [],
    usersDataFetchLoading: false,
    usersDataFetchError: undefined,
    assetAddError: undefined,
    addStatus: 'idle',
    deleteStatus: 'idle',
    updateStatus: 'idle'
};

// Redux slice for managing data
const userCrudSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        resetUserCrudState: (state) => initialState,
    },
    extraReducers: (builder) => {
        // Handle fetch data
        builder.addCase(fetchData.pending, (state) => {
            state.usersDataFetchLoading = true;
        });
        builder.addCase(fetchData.fulfilled, (state, action) => {
            state.usersDataFetchLoading = false;
            state.usersData = action.payload
        });
        builder.addCase(fetchData.rejected, (state, action) => {
            state.usersDataFetchLoading = false;
            state.usersDataFetchError  = action.error.message;
        });

        // Handle add row
        builder.addCase(addRow.pending, (state) => {
            state.addStatus = 'loading'
        });

        builder.addCase(addRow.fulfilled, (state, action) => {
            state.addStatus = 'succeeded'
            state.usersData.push(action.payload);
        });

        builder.addCase(addRow.rejected, (state, action) => {
            state.addStatus = 'failed';
            state.assetAddError = action.error.message;
        });

        builder.addCase(updateRow.pending, (state) => {
            state.updateStatus = 'loading'
        });

        // Handle update row
        builder.addCase(updateRow.fulfilled, (state, action) => {
            state.updateStatus = 'succeeded'
            const updatedRow = action.payload;
            const index = state.usersData.findIndex((row) => row.id === updatedRow.id);
            if (index >= 0) {
                state.usersData[index] = updatedRow;
            }
        });

        // Handle delete

        builder.addCase(deleteRow.pending, (state) => {
            state.deleteStatus = 'loading'
        });

        builder.addCase(deleteRow.fulfilled, (state, action) => {
            state.deleteStatus = 'succeeded'
            const rowId = action.payload;
            state.usersData = state.usersData.filter((row) => {
                return row.id !== rowId
            });
        });

        builder.addCase(deleteRow.rejected, (state, action) => {
            state.deleteStatus = 'failed';
        });
    },
});

export const {resetUserCrudState} = userCrudSlice.actions;

export default userCrudSlice.reducer;