import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, IconButton, TextField, Button, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Done, Delete, Visibility, ViewList, ViewModule } from '@mui/icons-material';
import PageTileAndBreadCrumb from "../components/PageTileAndBreadCrumb";
import Grid from "@mui/material/Grid2";
import AGDataTable from "../components/AGDataTable";

// Sample data types for notifications
interface Notification {
    id: string;
    title: string;
    type: 'Critical' | 'Warning' | 'Information' | 'Recommendation';
    asset: string;
    timestamp: string;
    description: string;
    isRead: boolean;
}

interface FilterOptions {
    type: string;
    asset: string;
    status: string;
}

// Sample data for notifications
const sampleNotifications: Notification[] = [
    { id: '1', title: 'High vibration detected', type: 'Critical', asset: 'Helena', timestamp: '2023-11-06 10:30 AM', description: 'Vibration level exceeded threshold on Motor #3', isRead: false },
    { id: '2', title: 'Maintenance required', type: 'Recommendation', asset: 'Crocker', timestamp: '2023-11-05 08:00 AM', description: 'Regular maintenance check recommended', isRead: true },
    { id: '3', title: 'Low efficiency detected', type: 'Warning', asset: 'Great Plains', timestamp: '2023-11-04 05:15 PM', description: 'Efficiency below optimal levels', isRead: false },
    { id: '4', title: 'Daily KPI update', type: 'Information', asset: 'Green River', timestamp: '2023-11-04 03:45 PM', description: 'KPI updated for daily performance', isRead: true },
];

const NotificationCenter: React.FC = () => {
    const [notifications, setNotifications] = useState<Notification[]>(sampleNotifications);
    const [filterOptions, setFilterOptions] = useState<FilterOptions>({ type: '', asset: '', status: '' });
    const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [view, setView] = useState<'card' | 'table'>('card');

    // Function to handle filtering notifications
    const handleFilterChange = (key: keyof FilterOptions, value: string) => {
        setFilterOptions(prev => ({ ...prev, [key]: value }));
    };

    // Filtered notifications based on filter options
    const filteredNotifications = notifications.filter(notification => {
        return (
            (filterOptions.type ? notification.type === filterOptions.type : true) &&
            (filterOptions.asset ? notification.asset === filterOptions.asset : true) &&
            (filterOptions.status ? (filterOptions.status === 'Unread' ? !notification.isRead : notification.isRead) : true)
        );
    });

    // Open dialog and set the selected notification
    const openNotificationDialog = (notification: Notification) => {
        setSelectedNotification(notification);
        setDialogOpen(true);
    };

    // Close dialog
    const closeDialog = () => {
        setDialogOpen(false);
        setSelectedNotification(null);
    };

    // Mark notification as read
    const markAsRead = (id: string) => {
        setNotifications(prev =>
            prev.map(notification => notification.id === id ? { ...notification, isRead: true } : notification)
        );
    };

    // Delete a notification
    const deleteNotification = (id: string) => {
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    };

    // Toggle between card and table view
    const handleViewChange = (event: React.MouseEvent<HTMLElement>, nextView: 'card' | 'table') => {
        if (nextView !== null) {
            setView(nextView);
        }
    };

    // Column definitions for ag-Grid
    const columnDefs = [
        { headerName: "Title", field: "title", flex: 1 },
        { headerName: "Type", field: "type", flex: 1 },
        { headerName: "Asset", field: "asset", flex: 1 },
        { headerName: "Timestamp", field: "timestamp", flex: 1 },
        { headerName: "Description", field: "description", flex: 2 },
        {
            headerName: "Actions",
            field: "actions",
            cellRenderer: (params: any) => (
                <Box>
                    <IconButton color="primary" onClick={() => openNotificationDialog(params.data)}>
                        <Visibility />
                    </IconButton>
                    <IconButton color="success" onClick={() => markAsRead(params.data.id)}>
                        <Done />
                    </IconButton>
                    <IconButton color="error" onClick={() => deleteNotification(params.data.id)}>
                        <Delete />
                    </IconButton>
                </Box>
            ),
            flex: 1,
        },
    ];

    return (
        <Grid container spacing={2}>
            <PageTileAndBreadCrumb title="Notification Center" />

            <Grid size={12}>
                <Card>
                    <CardContent>
                        {/* View Toggle */}
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <TextField
                                    label="Filter by Type"
                                    select
                                    value={filterOptions.type}
                                    onChange={(e) => handleFilterChange('type', e.target.value)}
                                    sx={{ width: '150px', marginRight: '1rem' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Critical">Critical</MenuItem>
                                    <MenuItem value="Warning">Warning</MenuItem>
                                    <MenuItem value="Information">Information</MenuItem>
                                    <MenuItem value="Recommendation">Recommendation</MenuItem>
                                </TextField>

                                <TextField
                                    label="Filter by Asset"
                                    select
                                    value={filterOptions.asset}
                                    onChange={(e) => handleFilterChange('asset', e.target.value)}
                                    sx={{ width: '150px', marginRight: '1rem' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Helena">Helena</MenuItem>
                                    <MenuItem value="Crocker">Crocker</MenuItem>
                                    <MenuItem value="Great Plains">Great Plains</MenuItem>
                                    <MenuItem value="Green River">Green River</MenuItem>
                                </TextField>

                                <TextField
                                    label="Status"
                                    select
                                    value={filterOptions.status}
                                    onChange={(e) => handleFilterChange('status', e.target.value)}
                                    sx={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Unread">Unread</MenuItem>
                                    <MenuItem value="Read">Read</MenuItem>
                                </TextField>
                            </Box>

                            {/* View Toggle Button */}
                            <ToggleButtonGroup
                                value={view}
                                exclusive
                                onChange={handleViewChange}
                                aria-label="view toggle"
                            >
                                <ToggleButton value="card" aria-label="card view">
                                    <ViewModule />
                                </ToggleButton>
                                <ToggleButton value="table" aria-label="table view">
                                    <ViewList />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            <Grid size={12}>
                {view === 'card' ? (
                    // Card View
                    filteredNotifications.map(notification => (
                        <Card key={notification.id} sx={{ marginBottom: '1rem', borderLeft: `4px solid ${notification.type === 'Critical' ? '#f44336' : notification.type === 'Warning' ? '#ffa726' : notification.type === 'Information' ? '#29b6f6' : '#66bb6a'}` }}>
                            <CardContent sx={{padding: '16px !important'}}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{notification.title}</Typography>
                                        <Typography color="text.secondary">{notification.asset} - {notification.timestamp}</Typography>
                                        <Typography variant="body2" sx={{ mt: 1 }}>{notification.description}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton color="primary" onClick={() => openNotificationDialog(notification)}>
                                            <Visibility />
                                        </IconButton>
                                        <IconButton color="success" onClick={() => markAsRead(notification.id)}>
                                            <Done />
                                        </IconButton>
                                        <IconButton color="error" onClick={() => deleteNotification(notification.id)}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    ))
                ) : (
                    // Table View using ag-Grid
                    <Card>
                        <CardContent>
                            <div className="ag-theme-quartz">
                                <AGDataTable
                                    rowData={filteredNotifications}
                                    colDefs={columnDefs}
                                    paginationPageSize={10}
                                    defaultColDef={[]}
                                    title={''}
                                />
                            </div>
                        </CardContent>
                    </Card>

                )}
            </Grid>

            {/* Notification Details Dialog */}
            <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="sm" fullWidth>
                <DialogTitle>Notification Details</DialogTitle>
                <DialogContent>
                    {selectedNotification && (
                        <>
                        <Typography variant="h6" gutterBottom>{selectedNotification.title}</Typography>
                            <Typography color="text.secondary">{selectedNotification.asset} - {selectedNotification.timestamp}</Typography>
                            <Typography variant="body1" sx={{ mt: 2 }}>{selectedNotification.description}</Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary" variant="contained">Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default NotificationCenter;