import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {fetchOEMPowerCurveData, fetchPowerCurveData, resetPCState} from "../../../slices/powerCurveSlice";
import Grid from "@mui/material/Grid2";
import {Card, CardContent} from "@mui/material";
import {HighchartsReact} from "highcharts-react-official";
import Highcharts from "highcharts";
import performancePCChartOptions from "../../../data/chartOptions/performance_pc_chart_options";
import FilterFormComponent from "../../../components/Filters";
import {resetFilters} from "../../../slices/filterSlice";
import {Info} from "@mui/icons-material";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";

const WindPCTab: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const {filterData, filtersLoading, filtersError} = useSelector((state: RootState) => state.filterData)
    const [chartOptions, setChartOptions] = useState(performancePCChartOptions);
    const {loading, error, pcData, oemPCData} = useSelector((state: RootState) => state.powerCurveData)
    const [defaultPCData, setDefaultPCData] = useState<any>({})
    const chart = useRef<HighchartsReact.RefObject>(null);
    const [showInitialMessage, setShowInitialMessage] = useState<boolean>(true)
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [userMessage, setUserMessage] = useState<{ message: string | null, alertSeverity: any }>({
        message: "",
        alertSeverity: ""
    });
    const {selectedAssetId} = useSelector((state: RootState) => state.global);
    let pcChart = chart.current?.chart;

    // Function to clear all series
    const clearSeries = () => {
        setChartOptions((prevOptions: any) => ({
            ...prevOptions,
            series: [], // Clear the series
        }));
    };

    useEffect(() => {
        dispatch(fetchOEMPowerCurveData(`oem-power-curve?asset_id=${selectedAssetId}`))
    }, []);

    useEffect(() => {
        if (oemPCData.length > 0) {
            let temp: any[] = []
            oemPCData.forEach((dataPoint: any) => {
                temp.push([dataPoint.wind_speed, dataPoint.power_output])
            })
            const newSeries = {
                type: 'spline',
                name: 'OEM Power Curve',
                data: temp,
            };
            setDefaultPCData(newSeries);
        }
    }, [oemPCData]);

    useEffect(() => {
        if (!loading && pcData.length > 0) {
            clearSeries();
            let tempSeries: any = []
            pcData.forEach((devicePCData: any) => {
                let devicePCDataSeries: any = []
                devicePCData.power_curve.forEach((dataPoint: any) => {
                    devicePCDataSeries.push([dataPoint.wind_speed_bin, dataPoint.average_active_power])
                })
                const newSeries = {
                    type: 'spline',
                    name: devicePCData.device_name + ' - Power Curve',
                    data: devicePCDataSeries,
                };
                tempSeries.push(newSeries);
            })

            tempSeries = tempSeries.concat(JSON.parse(JSON.stringify(defaultPCData)));

            setChartOptions((prevOptions: any) => ({
                ...prevOptions,
                series: [...prevOptions.series, ...tempSeries], // Add new series
            }));

            pcChart?.redraw()
            pcChart?.hideLoading()
        }
    }, [loading, pcData]);

    useEffect(() => {
        if (error) {
            setUserMessage({message: error, alertSeverity: "error"});
            setOpenSnackBar(true);
        }
    }, [error]);

    useEffect(() => {
        if (filterData && filterData.devices.length > 0) {
            setShowInitialMessage(false);
            pcChart?.showLoading()
            dispatch(fetchOEMPowerCurveData(`oem-power-curve?asset_id=${selectedAssetId}`))
            dispatch(fetchPowerCurveData(`power-curve?start_date=${filterData.timeFrame.startDate?.format('YYYY-MM-DD')}&end_date=${filterData.timeFrame.endDate?.format('YYYY-MM-DD')}&devices=${filterData.devices.map(item => item.id)}`))
        }
    }, [filterData]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters())
            dispatch(resetPCState())
        }
    }, [dispatch]);

    return (
        <>
            <Grid size={12}>
                <FilterFormComponent
                    showAssets={false}
                    showDevices={true}
                    showSignals={false}
                    showTimeFrame={true} />
            </Grid>

            <Grid size={12}>
                {
                    showInitialMessage &&
                    <Card>
                        <CardContent sx={{paddingBottom: '16px !important'}}>
                            <div className={'flex justify-center align-items-center items-center'}>
                                <div className={'flex flex-col justify-center align-items-center items-center'}>
                                    <Info fontSize={'small'}></Info>
                                    <span>Please select filters and click submit</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                }
                {
                    !showInitialMessage &&
                    <>
                        <Card>
                            <CardContent>
                                <HighchartsReact
                                    ref={chart}
                                    highcharts={Highcharts}
                                    options={chartOptions}>
                                </HighchartsReact>
                            </CardContent>
                        </Card>
                    </>
                }
            </Grid>

            <Snackbar open={openSnackBar}
                      autoHideDuration={6000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert
                    onClose={() => setOpenSnackBar(false)}
                    severity={userMessage.alertSeverity}
                    variant="filled"
                    sx={{width: '100%'}}>
                    {userMessage.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default WindPCTab;