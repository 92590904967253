import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {Route, Routes, useNavigate} from "react-router-dom";
import Home from "../pages/Home";
import FleetOverview from "../pages/fleet/Overview";
import WindOverview from "../pages/wind/WindOverview";
import SolarOverview from "../pages/solar/Overview";
import Settings from "../pages/admin/Settings";
import WindAssetDetails from "../pages/wind/WindAssetDetails";
import SideNavigation from "./SideNavigation";
import TopNavigation from "./TopNavigation";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store";
import WindDeviceDetails from "../pages/wind/WindDeviceDetails";
import WindAnalysis from "../pages/wind/Analysis/WindAnalysis";
import WindMonitoring from "../pages/wind/Monitoring/WindMonitoring";
import WindPerformance from "../pages/wind/Performance/WindPerformance";
import WindReports from "../pages/wind/Reports/WindReports";
import {fetchUserAssetsData, fetchUserData, fetchUserSubscriptionData} from "../slices/userSlice";
import WindFinance from "../pages/wind/Finance/WindFinance";
import WindMaintenance from "../pages/wind/Maintenance/WindMaintenance";
import WindPredict from "../pages/wind/Predict/WindPredict";
import DataPromptPage from "../pages/AIChat";
import NotificationCenter from "../pages/NotificationCentre";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const PageLayout : React.FC = () => {
    const [open, setOpen] = useState(false);
    const {assetType} = useSelector((state: RootState) => state.global)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(fetchUserData('users/me'))
        dispatch(fetchUserSubscriptionData('subscriptions'))
        dispatch(fetchUserAssetsData('assets'))
    }, []);

    return (
        <Box sx={{ display: 'flex' }} className={'bg-gray-100 min-h-screen'}>
            <CssBaseline />

            <TopNavigation
                isSidebarOpen={open}
                setIsSidebarOpen={setOpen}
            />
            {
                (assetType === 'wind' || assetType === 'solar') &&
                <SideNavigation
                    isSidebarOpen={open}
                    setIsSidebarOpen={setOpen}
                />
            }

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    <Route path="/fleet/overview" element={<FleetOverview/>}/>

                    <Route path="/wind/overview" element={<WindOverview/>}/>
                    <Route path="/solar/overview" element={<SolarOverview/>}/>

                    <Route path="/wind/monitoring" element={<WindMonitoring/>}/>
                    <Route path="/solar/monitoring" element={<WindMonitoring/>}/>

                    <Route path="/wind/analysis" element={<WindAnalysis/>}/>
                    <Route path="/solar/analysis" element={<WindAnalysis/>}/>

                    <Route path="/wind/performance" element={<WindPerformance/>}/>
                    <Route path="/solar/performance" element={<WindPerformance/>}/>

                    <Route path="/wind/reports" element={<WindReports/>}/>
                    <Route path="/solar/reports" element={<WindReports/>}/>

                    <Route path="/wind/predict" element={<WindPredict/>}/>
                    <Route path="/solar/predict" element={<WindPredict/>}/>

                    <Route path="/wind/finance" element={<WindFinance/>}/>
                    <Route path="/solar/finance" element={<WindFinance/>}/>

                    <Route path="/wind/maintenance" element={<WindMaintenance/>}/>
                    <Route path="/solar/maintenance" element={<WindMaintenance/>}/>

                    <Route path="/asset-details/:asset_id" element={<WindAssetDetails />} />
                    <Route path="/device-details/:device_id" element={<WindDeviceDetails />} />

                    <Route path="/admin/settings" element={<Settings/>}/>

                    <Route path="/user/profile" element={<Settings/>}/>

                    <Route path="/aichat" element={<DataPromptPage />}/>
                    <Route path="/notification-centre" element={<NotificationCenter />}/>
                </Routes>
            </Box>
        </Box>
    );
}

export default PageLayout;