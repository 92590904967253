import {
    Airplay,
    Alarm,
    AutoGraph,
    Domain,
    Headphones,
    Info,
    ManageAccounts,
    Subscriptions,
    VerifiedUser
} from "@mui/icons-material";

export const tabList = [
    {'icon': ManageAccounts, 'label': 'Users', id: 'manageAccounts'},
    {'icon': Domain, 'label': 'Assets', id: 'domains'},
    {'icon': Subscriptions, 'label': 'Subscriptions', id: 'subscriptions'},
]

