import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {fetchData} from "../../../../slices/pcCrudSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../store";
import {fetchAlarmCodes} from "../../../../slices/alarmCodeSlice";
import AGDataTable from "../../../../components/AGDataTable";
import {ColDef} from "ag-grid-enterprise";
import PowerCurveConfig from "./PowerCurve";


const PerformanceSettings: React.FC = () => {
    const [optionsValue, setOptionsValue] = useState('powerCurve');
    const [alarmCodesTableData, setAlarmCodesTableData] = useState([]);
    const {selectedAssetId} = useSelector((state: RootState) => state.global);

    const dispatch = useDispatch<AppDispatch>()

    const {
        alarmCodesData, loading: alarmCodesLoading, error: alarmCodesError
    } = useSelector((state: RootState) => state.alarmCodeData);


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            editable: true,
            filter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        };
    }, []);

    // Define the column definitions
    const columnDefs: ColDef[] = [
        { field: 'id', headerName: 'ID', hide: true },
        { field: 'asset_id', headerName: 'Asset ID', hide: true },
        { field: 'code', headerName: 'Code' },
        { field: 'description', headerName: 'Description' },
        { field: 'category', headerName: 'Category' },
        { field: 'custom_category', headerName: 'Custom Category' },
        { field: 'curtailment_category', headerName: 'Curtailment Category' },
        { field: 'loss_category', headerName: 'Loss Category' },
        { field: 'available', headerName: 'Available', cellRenderer: 'booleanCellRenderer' }, // Optionally add a boolean cell renderer
        { field: 'fault_type', headerName: 'Fault Type' },
        { field: 'gads_category', headerName: 'GADS Category' },
        { field: 'gads_component', headerName: 'GADS Component' },
        { field: 'iec_category', headerName: 'IEC Category' }
    ];


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptionsValue((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        if (optionsValue === 'powerCurve') {
            dispatch(fetchData('oem-power-curve?asset_id=' + selectedAssetId));
        }
        if (optionsValue === 'alarmCodes') {
            dispatch(fetchAlarmCodes('alarm_codes/asset/' + selectedAssetId));
        }
    }, [optionsValue]);

    useEffect(() => {
        if (alarmCodesData && alarmCodesData.length > 0) {
            setAlarmCodesTableData(alarmCodesData);
        }
    }, [alarmCodesData]);

    return (
        <Grid size={12}>
            <Card className={'mb-4'}>
                <CardContent sx={{paddingBottom: '16px !important'}}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={optionsValue}
                            onChange={handleChange}>
                            <FormControlLabel value="alarmCodes" control={<Radio />} label="Alarm Codes" />
                            <FormControlLabel value="powerCurve" control={<Radio />} label="Power Curve" />
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                </CardContent>
            </Card>
            {
                optionsValue === 'powerCurve' &&
                <PowerCurveConfig></PowerCurveConfig>
            }

            {
                optionsValue === 'alarmCodes' &&
                <Card>
                    <CardContent>
                        <AGDataTable
                            paginationPageSize={50}
                            loading={alarmCodesLoading}
                            height={800}
                            rowData={alarmCodesTableData}
                            colDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            title={'Alarm Codes'}></AGDataTable>
                    </CardContent>
                </Card>
            }

        </Grid>

    )
}

export default PerformanceSettings;