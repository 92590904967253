import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utils/interceptor";

const initialState = {
    aichatLoading: false,
    aichatData: {intent: "", asset: ""},
    aichatError: null as string | null,
};

export const fetchAichat = createAsyncThunk('data/aichatData', async (postData: any) => {
    const response = await api.post(postData.apiURL, postData.data);
    // Extract content
    const { content } = response.data;

    // Parse the content
    const parseResponse = (content: string) => {
      const match = content.match(/Intent:\s*(\w+),\s*Asset:\s*([\w\s]+)/);
      if (match) {
        return {
          intent: match[1],
          asset: match[2],
        };
      }
      return null; // Return null if parsing fails
    };

    // Convert to JSON
    const jsonOutput = JSON.stringify(parseResponse(content), null, 2);

    return JSON.parse(jsonOutput);
});

const aichatSlice = createSlice({
    name: 'aichat',
    initialState,
    reducers: {
        resetAichatState: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAichat.pending, (state) => {
                state.aichatLoading = true;
                state.aichatError = null;
            })
            .addCase(fetchAichat.fulfilled, (state, action) => {
                state.aichatLoading = false;
                state.aichatData = action.payload;
            })
            .addCase(fetchAichat.rejected, (state, action) => {
                state.aichatLoading = false;
                state.aichatError = action.error.message || 'Failed to post data';
            });
    },
});

export const { resetAichatState } = aichatSlice.actions;
export default aichatSlice.reducer; 